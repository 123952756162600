<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td><span>Product Name:</span> GRICD MOTE50</td>
        </tr>
        <tr>
          <td><span>Color:</span> Black and grey</td>
        </tr>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td><span>Product Tag:</span> MOTE, MOTE15, MOTE50</td>
        </tr>
        <tr>
          <td><span>Product Category:</span> Mote</td>
        </tr>
        <tr style="background: rgba(230, 236, 238, 0.46);">
          <td><span>Care Label:</span> Store in cool and dry place.</td>
        </tr>
        <tr>
          <td><span>Weight (kg):</span> 8.60kg</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'Table2',
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
}
td {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  /* Text */

  color: #212529;
}
</style>
